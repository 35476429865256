<template>
  <div class="goodsDetail">
    <div class="goodsDetail-card">
      <div class="goods-message">
        <div class="goods-pic">
          <img :src="this.goodDetail.image" :alt="goodDetail.title" />
          <div class="mask">
            <img :src="require('@/assets/images/Mall/pc-sellout.png')" alt="" />
          </div>
        </div>
        <div class="goods-des">
          <div class="product_img">
            <img :src="require('@/assets/images/Mall/microsoft.png')" alt="" />
          </div>
          <div class="product_title">
            {{ this.goodDetail.title }}
          </div>
          <div class="star-box"></div>
          <div class="product-Price-amount">
            <span class="old-price">${{ this.goodDetail.marketprice }}</span>
            <span class="price">${{ this.goodDetail.price }}</span>
          </div>
          <div class="product-detail-text">
            <div class="li-list-box">
              <div class="li">Lifetime License – One-Time Payment</div>
              <div class="li">Original/Genuine License</div>
              <div class="li">Multilingual – All languages supported</div>
              <div class="li">Payment Terms: USDT</div>
            </div>
            <div class="pay-box">
              <div class="icon"></div>
              <div class="text">One-time payment</div>
            </div>
            <div class="num-box">
              <div class="changenum">
                <div class="ko-sub" @click="changenum('-')">
                  <img
                    :src="require('@/assets/images/Mall/reduce.png')"
                    alt="" />
                </div>
                <input type="text" class="numbox" v-model="totalnum" />
                <div class="ko-add" @click="changenum('+')">
                  <img :src="require('@/assets/images/Mall/add.png')" alt="" />
                </div>
              </div>

              <div class="Stockbox">Stock: {{ this.goodDetail.stocks }}</div>
            </div>
            <div class="fun-btn-box">
              <el-button
                class="car-buy-btn"
                @click="addToCarOnClick(goodDetail, totalnum)"
                >Add To Cart</el-button
              >
              <el-button
                class="car-buy-btn"
                @click="checkOutOnClick(goodDetail)"
                >Check out</el-button
              >
            </div>
            <div class="product_meta">
              <div class="icon"></div>
              <div class="text">Instant Delivery to your Email</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-box">
        <button
          class="tabs-btn"
          v-bind:class="{ 'tabs-active': activeTab === 'tab1' }"
          @click="activeTab = 'tab1'">
          Description
        </button>
        <div class="tab-description" v-show="activeTab === 'tab1'">
          <div class="tab-noreviews" v-html="this.goodDetail.description"></div>
        </div>
        <div class="tab-description" v-show="activeTab === 'tab2'">
          <p class="tab-noreviews">There are no reviews yet.</p>
        </div>
      </div>

      <!-- 相关推荐 -->
      <div>
        <goodListCard :goodsLists="goodsList" :titleName="titleName" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mallGoodsDetailApi,
  addCarApi,
  mallGoodsListApi,
} from "@/network/api.js";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
export default {
  name: "MallDetail",
  data() {
    return {
      detaildata: {
        title: "",
        image: "",
        price: null,
        stocks: null,
        content: "",
      },
      goodDetail: [],
      goodsList: [],
      totalnum: 1, //默认购买数量
      activeTab: "tab1", // 默认激活的标签页
      titleName: "Related Products",
      page: 1,
      TotalPages: 1,
      localCarList: [],
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
    ...mapState(["carList"]),
    mbGoodsList() {
      return _.chunk(this.goodsList, [2]);
    },
    formattedDescription() {
      if (this.goodDetail && this.goodDetail.description) {
        // Use replace method if description exists
        return this.goodDetail.description.replace(/\r\n/g, "<br>");
      } else {
        // Provide a default value or handle the situation as needed
        return "There are no description yet.";
      }
    },
    detailId() {
      return this.$route.params?.id;
    },
  },
  watch: {
    $route: {
      handler(newId, oldId) {
        if (newId !== oldId) {
          this.getGoodDetail();
          setTimeout(() => {
            window.scrollTo(0, 0);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.localCarList = this.carList || [];
    this.getGoodDetail();
    // this.getHotList();
    window.scrollTo(0, 0);
  },

  methods: {
    ...mapMutations(["setCarList", "setShowTopCart"]),
    ...mapActions(["getCartList"]),

    async getGoodDetail() {
      const params = {
        keywords: this.detailId,
      };
      const res = await mallGoodsDetailApi(params);
      this.goodDetail = res.data;
      this.getHotList();
    },
    async addToCarOnClick(item, inNum = 1) {
      if (this.isLogin) {
        const params = {
          goods_id: item.id,
          nums: inNum,
        };
        try {
          const res = await addCarApi(params);
          this.$message.success("Added successfully");
          await this.getCartList();
          this.setShowTopCart(true);
        } catch (error) {}
      } else {
        const ids = this.localCarList.map((wItem) => wItem.goods_id);
        console.log(12345, this.carList);
        if (!ids.includes(item.id)) {
          this.localCarList.push({
            goods: item,
            nums: inNum,
            goods_id: item.id,
          });
        } else {
          this.localCarList.forEach((vItem) => {
            if (vItem.goods_id === item.id) {
              vItem.nums = vItem.nums * 1 + inNum * 1;
            }
          });
        }
        this.setCarList(this.localCarList);
        this.$message.success("Added successfully");
      }
    },
    changenum(v) {
      if (v == "-") {
        if (this.totalnum < 2) {
          this.$message({
            message: "Minimum purchase of one item!",
            type: "error",
          });
          this.totalnum = 1;
        } else {
          this.totalnum = this.totalnum - 1;
        }
      } else if (v == "+") {
        if (this.totalnum >= 100) {
          this.$message({
            message: "Single purchase limit of 100 pieces!",
            type: "error",
          });
        } else {
          this.totalnum = this.totalnum - 0 + 1;
        }
      }
    },

    async getHotList() {
      const params = {
        // flag: "hot",
        // page: this.page,
        type: "marketprice",
        sort: "asc",
        page: 1,
        category_id: this.goodDetail.category_id,
      };
      const res = await mallGoodsListApi(params);
      this.goodsList = res.data.Items.slice(0, 4);
      this.TotalPages = res.data.TotalPages;
    },
    async checkOutOnClick(item) {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      this.$router.push("/Checkout");
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsDetail {
  width: 100%;
  min-height: 90vh;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding: 0.2rem 0;
  background: #fff;
  font-family: "DM Sans", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 2rem;
  .goodsDetail-card {
    position: relative;
    width: 60%;
    margin: 0 auto;
    margin-top: 50px;
    background-color: #fff;

    .goods-message {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1.2rem;

      .goods-pic {
        width: 4.72rem;
        height: 4.72rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.6rem;
        position: relative;
        max-width: 100%;

        .mask {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0, 0, 0, 0.4);
          // transform: scale(1.1);

          img {
            position: absolute;
            bottom: 0.2rem;
            right: 0.2rem;
            width: 1.03rem;
            height: 1.03rem;
          }
        }
        img {
          // width: 100%;
          height: 100%;
        }
      }
      .goods-des {
        width: 50%;
        margin-left: 50px;
        text-align: left;

        .product_img {
          width: 1.22rem;
          height: 0.4rem;
          margin-bottom: 0.1rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .product_title {
          font-weight: 500;
          font-size: 0.28rem;
          color: #333;
          margin-bottom: 0.24rem;
          font-weight: 600;
        }
        .star-box {
          width: 1.4rem;
          height: 0.22rem;
          background-image: url(../../assets/images/Mall/star.png);
          background-size: 100% 100%;
          margin-bottom: 0.2rem;
        }
        .product-Price-amount {
          display: flex;
          justify-content: flex-start;
          font-size: 41px;
          line-height: 25px;
          font-weight: 600;
          text-align: left;
          margin-bottom: 0.22rem;
          margin-top: 0.18rem;
          .old-price {
            text-decoration: line-through;
            padding-right: 10px;
            color: #848484;
            opacity: 0.6;
            font-size: 0.24rem;
          }
          .price {
            color: #333;
            font-size: 0.36rem;
          }
        }
        .product-detail-text {
          font-size: 14px;
          color: #666666;
          letter-spacing: 1px;
          font-family: "DM Sans", system-ui, -apple-system, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, "Noto Sans", sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
          .li-list-box {
            font-weight: 400;
            font-size: 0.14rem;
            color: #666666;
            margin-bottom: 0.1rem;
            .li {
              padding-left: 0.2rem;
              line-height: 0.28rem;
              position: relative;
            }
            .li::before {
              content: "";
              position: absolute;
              left: 0.05rem;
              top: 50%;
              transform: translateY(-50%);
              width: 0.05rem;
              height: 0.05rem;
              background: #666;
              border-radius: 50%;
            }
          }
          .product-detail-des {
            padding: 3px 0;
            padding-left: 0.2rem;
            line-height: 0.2rem;
            position: relative;
          }
          .product-detail-des::before {
            content: "";
            position: absolute;
            left: 0.05rem;
            top: 50%;
            transform: translateY(-50%);
            width: 0.05rem;
            height: 0.05rem;
            background: #666;
            border-radius: 50%;
          }
        }
        .pay-box {
          display: flex;
          align-items: center;
          margin: 0.1rem 0;
          font-size: 0.14rem;
          .icon {
            width: 0.18rem;
            height: 0.18rem;
            margin-right: 0.06rem;
            background-image: url(../../assets/images/Mall/pay-icon.png);
            background-size: 100% 100%;
          }
        }
        .num-box {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 0.2rem;
          font-size: 0.14rem;
          .changenum {
            width: 0.96rem;
            height: 0.26rem;
            flex-shrink: 0;
            display: flex;
            margin-right: 0.15rem;
            .ko-sub {
              width: 0.24rem;
              height: 0.26rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 0.01rem solid #ffb100;
              cursor: pointer;
              img {
                width: 0.16rem;
                height: 0.16rem;
              }
            }
            .numbox {
              width: 0.48rem;
              height: 0.26rem;
              border: 0.01rem solid #ffb100;
              font-weight: 400;
              font-size: 0.12rem;
              text-align: center;
              color: #333;
              padding: 0;
              border-left: 0;
              border-right: 0;
            }
            .ko-add {
              width: 0.24rem;
              height: 0.26rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 0.01rem solid #ffb100;
              cursor: pointer;
              img {
                width: 0.16rem;
                height: 0.16rem;
              }
            }
          }
        }

        .fun-btn-box {
          display: flex;
          // justify-content: space-between;
          margin-bottom: 0.2rem;
          .car-buy-btn {
            width: 1.22rem;
            height: 0.32rem;
            background: #ffb100;
            font-size: 0.14rem;
            text-align: center;
            // line-height:0.32rem;
            color: #fff;
            cursor: pointer;
            margin-right: 0.08rem;
          }
          .car-buy-btn:hover {
            background-color: #0e1422;
          }
        }
        .product_meta {
          display: flex;
          align-items: center;
          margin-top: 0.1rem;
          .icon {
            width: 0.18rem;
            height: 0.18rem;
            margin-right: 0.06rem;
            background-image: url(../../assets/images/Mall/text-icon.png);
            background-size: 100% 100%;
          }
          .text {
            font-weight: 400;
            font-size: 0.14rem;
            color: #666;
          }
        }
      }
    }

    .tabs-box {
      margin-top: 0.4rem;
      text-align: left;
      margin-bottom: 1rem;
      .tabs-btn {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        border: 0px;
        margin: 0;
        border-radius: 0;
        padding: 15px 32px;
        font-size: 16px;
        font-weight: 400;
        background-color: #f0f0f0;
        color: #515151;
        margin-right: 10px;
      }
      .tabs-active {
        background-color: #ffb100;
        color: #fff;
      }
      .tab-description {
        padding: 30px 25px;
        border: 1px solid #eaeaea;
        margin-top: -1px;

        .tab-noreviews {
          font-size: 16px;
          line-height: 26px;
          color: #666666;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }
    }

    .el-input-number .el-input__inner:hover {
      color: #ffb100;
      border-color: #ffb100;
    }
  }
}
</style>
<style>
.el-button {
  border: none;
  border-radius: 0;
  line-height: 0.7;
  padding: 9px 20px;
}
</style>
>
